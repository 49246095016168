<template>
  <div>
    <div 
      class="home-banner"
      :min-height="$vuetify.display.mobile ? '80px': '150px'"
    >
      <v-carousel
        :show-indicators="false"
        :show-controls="false"
        :style="{height:'300'}"
        :cycle="true" 
        hide-delimiters
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          fade
        >
          <v-img :src="item.src" max-height="300"></v-img>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="about-section">
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            class="col-sm-12"
          >
            <h2 class="title-text">About Us</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="description-text text-justify"
          >
            LeachGarner is a global precious metal solution provider (A Berkshire Hathaway Company). We design, fabricate and distribute precious metal alloys, 
            mill products and jewelry findings to a number of industries including electronics, medical, automotive, jewelry and numismatic. Headquartered in 
            Attleboro, MA USA, LeachGarner has sales, distribution and manufacturing facilities in the US, Hong Kong and China.&nbsp;LeachGarner brands 
            include&nbsp;General Findings, Excell Chains and Stern Metals as shown below:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="$vuetify.display.mobile ? 12:4"
            class="text-center"
          >
            <a href="/products?cat=Findings">
              <img
                src="..\assets\aboutus\AboutUS_Findings.jpg"
                alt="General Findings"
                width="100%"
                height="260px"
              />
            </a>
            <h5 class="mt-2 img-text">General Findings</h5>
          </v-col>
          <v-col
            :cols="$vuetify.display.mobile ? 12:4"
            class="text-center"
          >
            <a href="/products?cat=Chains">
              <img
                src="..\assets\aboutus\AboutUS_Chains.jpg"
                alt="Excell Chains"
                width="100%"
                height="260px"
              />
            </a>
            <h5 class="mt-2 img-text">Excell Chains</h5>
          </v-col>
          <v-col
            :cols="$vuetify.display.mobile ? 12:4"
            class="text-center"
          >
            <a href="/products?cat=Mills">
              <img
                src="..\assets\aboutus\AboutUS_Mills.jpg"
                alt="Stern Metals"
                width="100%"
                height="260px"
              />
            </a>
            <h5 class="mt-2 img-text">Stern Metals</h5>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConversionTablesPage",
  data () {
    return {
      items: [
        {
          id:'slide1', src:require('@/assets/aboutus/1597118338jzCBY.jpg'), title:'Slide 1'
        },
        {
          id: 'slide2', src:require('@/assets/aboutus/1597118422U6wdU.jpg'), title: 'Slide 2'
        },
        {
          id: 'slide3', src:require('@/assets/aboutus/1597118447FkC1x.jpg'), title: 'Slide 3'
        },
        {
          id: 'slide4', src:require('@/assets/aboutus/1597118463dq9jA.jpg'), title: 'Slide 4'
        },
        {
          id: 'slide5', src:require('@/assets/aboutus/1597118475STuBH.jpg'), title: 'Slide 5'
        },
        {
          id: 'slide6', src:require('@/assets/aboutus/15971181409QOIa.jpg'), title: 'Slide 6'
        },
        {
          id: 'slide7', src:require('@/assets/aboutus/15971183703eDAj.jpg'), title: 'Slide 7'
        },
        {
          id: 'slide8', src:require('@/assets/aboutus/17016647308U5IO.jpg'), title: 'Slide 8'
        },
        {
          id: 'slide9', src:require('@/assets/aboutus/slider1.jpg'), title: 'Slide 9'
        }
      ]
    }
  }
}
</script>

<style>
.about-section {
  padding: 50px 0;
  min-height: 100vh;
  width: 100%;
}

.description-text {
  font-size: 16px;
  color: #000;
  line-height: 26px;
  font-weight: 500;
}

h5.img-text {
  text-align: center !important;
  color: #AA8E52 !important;
  font-size: 20px !important;
  font-family: times new roman,times,serif !important;
  font-weight:bolder;
  text-transform: uppercase;
  margin-top: 0.5rem;
  height: 25px;
}

.carousel {
  margin-bottom: 2rem;
}

.carousel-item img {
  height: 35vh;
  object-fit: cover;
}

.v-btn--size-default {
  width:30px !important;
  height:30px !important;
}

.v-window__controls {
  margin-top: 10px;
  position:absolute;
  width: calc(100% + 30px) !important;
  display: flex;
  justify-content: space-between;
  left: -14px;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

.v-window__controls button {
  background-color: #b3890a !important;
}

@media only screen and (max-device-width: 480px) {
  .v-btn--size-default {
    width:25px !important;
    height:25px !important;
  }
}

.home-banner .mdi-chevron-right, .home-banner .mdi-chevron-left {
  color: #fff;
  font-weight: 600;
}

.home-banner {
  width: 1170px;
  margin: 0 auto;
  max-width: 100%;
}
</style>
