<template>
  <v-container fluid>
    <v-row>
      <v-col 
        v-if="!$vuetify.display.xs"
        cols="3"
      >
        <v-expansion-panels
          v-model="panels"
          v-if="facets"
          multiple
          flat
          accordion
        >
          <v-expansion-panel
            v-for="(facet, ii) in facets"
            :key="ii"
            active-class="facets__panel--active"
            class="facets__panel"
            :value="facet.category"
          >
            <v-expansion-panel-title 
              class="pa-0 facets__icon"
              :class="category?.toLowerCase().includes(facet.category.toLowerCase()) ? 'font-weight-bold' : ''"  
            >
              <template v-slot:default="{ }">
              {{ facet.category }}
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="px-0 pb-4"
            >
              <v-list-item
                v-for="option in facet.subCategories"
                :key="`${facet.category}-${option}`"
                class="pa-0 facets__item"
                dense
              >
                <a 
                  :href="$router.resolve({name: 'Products', query: { 'cat': facet.category, 'sub': option }}).href"
                  :class="subCategory?.toLowerCase().includes(option.toLowerCase()) ? 'font-weight-bold' : ''"
                >
                  <span>
                    > {{ option }}
                  </span>
                </a>
              </v-list-item>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col
        v-if="$vuetify.display.xs"  
        cols="12"
      >
        <v-list>
          <v-list-group value="Products">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Products"
              ></v-list-item>
            </template>
            <v-list-group 
              v-for="(facet, ii) in facets"
              :key="ii"
            >
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" :title="facet.category"></v-list-item>
              </template>
              <v-list-item
                v-for="option in facet.subCategories"
                :key="`${facet.category}-${option}`"
                class="pa-0 facets__item"
                dense
              >
                <a 
                  :href="$router.resolve({name: 'Products', query: { 'cat': facet.category, 'sub': option }}).href"
                  :class="subCategory?.toLowerCase().includes(option.toLowerCase()) ? 'font-weight-bold' : ''"
                >
                  <span>
                    > {{ option }}
                  </span>
                </a>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-col>

      <v-col cols="12" sm="9">
        <v-row class="ma-0">
          <v-col 
            cols="12"
            sm="6"
            class="section-title"
          >
            <span v-if="category">
              {{ category }} &nbsp;
            </span>
            <span v-if="subCategory">
              > {{ subCategory }}
            </span>
            <span v-if="query">
              Search Results for: '{{ query }}'
            </span>
          </v-col>
          <v-col 
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="searchText"
              class="search-entry"
              append-inner-icon="mdi-magnify"
              density="compact"
              label="Search Product"
              variant="solo"
              hide-details
              single-line
              @click:append-inner="search"
              @keydown.enter="search"
            />
          </v-col>
          
        </v-row>
        <v-container fluid class="products-grid">
          <div
            v-for="(product, index) in displayProducts"
            :key="index"
            class="py-3 px-2 grid-item"
          >
            <v-lazy>
              <div class="product-info">
                <v-img
                  alt=""
                  :src="getImageUrl(product.imgName, 1)"
                  width="100%"
                />
                <p>
                  <span class="pr-title">{{ product.title }}</span> <br>
                  {{ product.desc }} <br>
                  <v-btn
                    class="learn-more mt-2"
                    size="x-small"
                    rounded="xs"
                    outlined
                    @click="openDialog(product)"
                  >
                    Learn more
                  </v-btn>
                </p>
              </div>
            </v-lazy>
          </div>
          <v-dialog
            v-model="quickViewModal"
            max-width="970"
            max-height="870"
            class="quick-view-dialog"
          >
            <v-card class="quick-view-dialog__card">
              <v-card-title class="d-flex justify-space-between align-center">
                <div class="text-h5 text-medium-emphasis ps-2">
                  Product Details
                </div>

                <v-btn
                  icon="mdi-close"
                  variant="text"
                  @click="quickViewModal = false"
                ></v-btn>
              </v-card-title>
              
              <v-container fluid class="qw-content">
                <v-row>
                  <v-col cols="12" sm="4">
                      <inner-image-zoom
                        :src="getImageUrl(qwProduct.imgName, selectedImageIndex)"
                        zoomScale="0.5"
                      />
                    <br>
                    <v-divider class="border-opacity-100" color="#be9b59"/>
                    <br>
                    <div class="d-flex thumbnails">
                      <v-img
                        v-for="(image, i) in Number(qwProduct.imgCount)"
                        :key="i"
                        class="thumbnail"
                        :class="selectedImageIndex == i + 1 ? 'selected-thumb' : ''"
                        height="85px !important"
                        width="85px !important"
                        :src="getImageUrl(qwProduct.imgName, image)"
                        @click="selectedImageIndex = i + 1"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <h3 class="desc-title">
                      <span class="pr-title">{{ qwProduct.title }}</span><br>
                      {{ qwProduct.desc }}
                    </h3>
                    <v-row 
                      v-if="qwProduct.itemNo" 
                      no-gutters
                      class="py-2 desc-block"
                    >
                      <v-col cols="6">Item</v-col>
                      <v-col cols="6"><span v-html="qwProduct.itemNo" class="pr-desc"></span></v-col>
                    </v-row>
                    <v-row 
                      v-if="qwProduct.material" 
                      no-gutters
                      class="py-2 desc-block"
                    >
                      <v-col cols="6">Material</v-col>
                      <v-col cols="6"><span v-html="qwProduct.material" class="pr-desc"></span></v-col>
                    </v-row>
                    <v-row 
                      v-if="qwProduct.color" 
                      no-gutters
                      class="py-2 desc-block"
                    >
                      <v-col cols="6">Colour</v-col>
                      <v-col cols="6"><span v-html="qwProduct.color" class="pr-desc"></span></v-col>
                    </v-row>
                    <v-row 
                      v-if="qwProduct.size" 
                      no-gutters
                      class="py-2 desc-block"
                    >
                      <v-col cols="6">Size</v-col>
                      <v-col cols="6"><span v-html="qwProduct.size" class="pr-desc"></span></v-col>
                    </v-row>
                    <v-row 
                      v-if="qwProduct.weight" 
                      no-gutters
                      class="py-2 desc-block"
                    >
                      <v-col cols="6">Weight (approx.)</v-col>
                      <v-col cols="6"><span v-html="qwProduct.weight" class="pr-desc"></span></v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-container>
        
        <v-pagination
          v-model="currentPage"
          active-color="#ddca91"
          :length="totalPages"
          total-visible="4"
          size="small"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import categoriesData from "../assets/products.json";
import InnerImageZoom from 'vue-inner-image-zoom'

export default {
  components: {
    'inner-image-zoom' : InnerImageZoom
  },
  data() {
    return {
      facets: categoriesData.categories,
      products: categoriesData.products,
      qwProduct: {},
      searchText: "",
      selectedImageIndex: 1,
      quickViewModal: false,
      currentPage: 1,
      category: null,
      subCategory: null,
      query: null,
      panels: []
    }
  },
  mounted() {
    var urlParams = new URLSearchParams(window.location.search)
    this.category = urlParams.get('cat')
    this.subCategory = urlParams.get('sub')
    this.currentPage = urlParams.get('page') || 1
    this.query = urlParams.get('q')
    if (this.category) {
      this.panels.push(this.category)
    }
  }, 
  computed: {
    filteredProducts() {
      var products = this.products
      if (this.category || this.subCategory) {
        products = this.products.filter((product) =>
          (!this.category || product.category.toLowerCase().includes(this.category.toLowerCase())) &&
          (!this.subCategory || product.subCategory.toLowerCase().includes(this.subCategory.toLowerCase()))
        )
      } else if (this.query) {
        products = this.products.filter((product) =>
          product.itemNo.toLowerCase().includes(this.query.toLowerCase()) ||
          product.title.toLowerCase().includes(this.query.toLowerCase())
        )
      }
      return products;
    },
    displayProducts() {
      return this.filteredProducts.slice(0 + (this.currentPage - 1) * 24, this.currentPage * 24);
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / 24);
    }
  },
  methods: {
    openDialog(product) {
      this.selectedImageIndex = 1
      this.qwProduct = product
      this.quickViewModal = true
    },
    search() {
      if (this.searchText && this.searchText.trim()) {
        window.location.href = this.$router.resolve({name: 'Products', query: { 'q': this.searchText }}).href
      }
    },
    getImageUrl(name, index) {
      return "https://lghkprepstore.blob.core.windows.net/images/" + name + "-" + index + ".jpg"
    }
  },
};
</script>

<style>
.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
	min-height: 48px;
}
.v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
	margin-top: 0px;
}
.products-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto;
  column-gap: 15px;
}
.product-info {
  font-size: 12px;
}
.grid-item {
	background: #fff;
	box-shadow: 2px 2px 5px #eff0f2;
	border-radius: 5px;
	margin-bottom: 30px;
	padding: 7px;
}
.learn-more .v-btn__content{
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: normal;
}
.v-btn.learn-more {
  background: #be9b59;
  color: #fff;
}
.product-info .v-img {
	border-bottom: 1px solid #be9b59;
	margin-bottom: 15px;
}
.product-info p {
  font-size: 12px;
  line-height: 1.3;
	overflow: hidden;
	max-width: 185px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.v-pagination__item, .v-pagination__first, .v-pagination__prev, .v-pagination__next, .v-pagination__last {
	margin: 0.1rem;
}
.v-pagination__item button{
	border: 1px solid lightgrey;
}
.v-list-item--density-default {
	min-height: 30px;
}
.v-expansion-panel {
	color: #b38707;
  font-size: 16px;
}
.v-expansion-panel-text__wrapper {
	padding: 0px 24px 16px;
}
.v-expansion-panel-title {
	font-size: 18px;
}
.v-list-item__content a {
	color: inherit;
	text-decoration: none;
}
.section-title {
	color: #be9b59;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
}
.thumbnails {
  flex-wrap: wrap;
  column-gap: 8px;
}
.thumbnail {
  flex-grow: 0;
}
@media screen and (max-width: 960px) {
  .products-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .v-overlay__content .v-card-title{
    position: fixed;
    z-index: 100;
    width: 100%;
    background-color: white;
  }
  .qw-content {
    margin-top: 50px;
  }
  .v-overlay__content {
    max-height: 100vh;
  }
}

.quick-view-dialog .v-overlay__content {
  max-height: 100vh !important;
}
@media screen and (max-width: 600px) {
  .products-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.desc-block {
  font-size: 14px;
  font-weight: 600;
}
h3.desc-title {
	font-size: 20px;
	color: #b38707;
	font-weight: 600;
	border-bottom: 2px solid;
	padding-bottom: 15px;
	margin-bottom: 20px;
}
.selected-thumb {
  border: 1px solid #b38707;
}
</style>