<template>
  <div class="pb-12">
    <v-container fluid>
      <h2 class="title-text">Conversion Tables</h2>
      <v-row>
        <v-col cols="12">
          <p>
            <img src="..\assets\guidereference\AllConversionTables.jpg">
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "ConversionPage",
}
</script>