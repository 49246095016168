import { createRouter, createWebHistory } from 'vue-router';
import AboutPage from '../views/AboutPage.vue';
import ProductsPage from '../views/ProductsPage.vue';
import ConversionPage from '../views/ConversionPage.vue';
import GoldTypesPage from '../views/GoldTypesPage.vue';
import LGVideosPage from '../views/LGVideosPage.vue';
import ContactUsPage from '../views/ContactUsPage.vue';
import InvernessPage from '../views/InvernessPage.vue';

const routes = [
  { path: '/', name: 'Home', component: AboutPage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/products', name: 'Products', component: ProductsPage },
  { path: '/conversion', name: 'Conversion', component: ConversionPage },
  { path: '/gold', name: 'Gold', component: GoldTypesPage },
  { path: '/videos', name: 'Videos', component: LGVideosPage },
  { path: '/contact', name: 'contact', component: ContactUsPage },
  { path: '/inverness', name: 'Inverness', component: InvernessPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
