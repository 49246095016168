<template>
  <div class="pb-12">
    <v-container fluid>
      <h2 class="title-text">LeachGarner Video</h2>
      <v-row>
        <v-col 
          cols="12"
          class="pr-4"
        >
          <p>            
            LeachGarner has a long and prestigious lineage providing precious metals products and services to a wide range of industries.
            We have our over 500 employees worldwide allowing us to stay committed to Innovation, Leadership, Quality, and providing best-in-class Customer Experiences.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>Quality Casting Begins with Quality Grain from LeachGarner</p>
          <video
            autoplay="true"
            muted="true"
            loop="flase"
            controls="true"
            :width="!$vuetify.display.mobile ? '400' : '345'" 
          >
            <source
              src="..\assets\guidereference\LGUSQualityCastingQualityGrain.mp4"
              type="video/mp4"
            >
          </video>
        </v-col>
      </v-row> 
      <v-row>
        <v-col cols="12">
          <p>LeachGarner Bangles</P>
          <video
            muted="true"
            loop="flase"
            controls="true"
            :width="!$vuetify.display.mobile ? '400' : '345'" 
          >
            <source
              src="..\assets\guidereference\LGUSBangles.mp4"
              type="video/mp4"
            >
          </video>
        </v-col>
      </v-row> 
      <v-row>
        <v-col cols="12">
          <p>LeachGarner Bangles</P>
          <video
            muted="true"
            loop="flase"
            controls="true"
            :width="!$vuetify.display.mobile ? '400' : '345'" 
          >
            <source
              src="..\assets\guidereference\LGUSVideo.mp4"
              type="video/mp4"
            >
          </video>
        </v-col>
      </v-row> 
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LGVideosPage",
}
</script>
