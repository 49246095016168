<template>
  <div class="pb-12">
    <v-container 
      fluid
      class="website-details"
    >
      <h2 class="title-text" >Contact Us</h2>
      <v-row>
        <v-col :cols="$vuetify.display.mobile ? 12 : 4">
          <h3 class="contact-title">ASIA PACIFIC</h3>
          <h4 class="contact-title">Leach & Garner (HK) Limited</h4>
          <p>
            <span>Unit A1-A4, 18/F, Gemstar Tower,
              <br/>
              23 Man Lok Street,
              <br/>
              Hunghom, KLN, Hong Kong
              <br/>
              P: +852-2770-0323
              <br/>
              F: +852-2780-5231
              <br/>
              E: <a href="mailto:info@lghk.com">info@lghk.com</a>
              <br/>
              <a href="https://www.LGHK.com" target="_blank">www.LGHK.com</a>
            </span>
            <img src="..\assets\contactus\LGHKOffice.jpg">
          </p>
        </v-col>
        <v-col :cols="$vuetify.display.mobile ? 12 : 4">
          <h3 class="contact-title">HEADQUARTER</h3>
          <h4 class="contact-title">LeachGarner Inc.</h4>
          <p>
            <span>49 Pearl Street
              <br/>
              Attleboro, MA 02703
              <br/>
              <br/>
              P: 1-800-225-2706
              <br/>
              F: 1-508-222-6531
              <br/>
              E: <a href="mailto:info@leachgarner.com">info@leachgarner.com</a>
              <br/>
              <a href="https://www.LeachGarner.com" target="_blank">www.LeachGarner.com</a>
            </span>
            <img src="..\assets\contactus\LGUSOffice.jpg">
          </p>
        </v-col>
        <v-col :cols="$vuetify.display.mobile ? 12 : 4">
          <h3 class="contact-title mb-4">AUTHORIZED DISTRIBUTORS</h3>
          <ContactUsPanelsPage/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import ContactUsPanelsPage from '@/views/ContactUsPanelsPage'

export default {
  name: "ContactUsPage",
  components: {
    ContactUsPanelsPage
  },
}
</script>

<style>
.contact-section {
  color: #000 !important;
  letter-spacing: 1px;
}

h3.contact-title {
  font-size: 22px;
}

h4.contact-title {
  font-size: 20px;
}

.website-details p {
  font-size: 16px;
  color: #000;
  line-height: 26px;
}

a {
  color: #000;
  text-decoration: none;
}
</style>
