<template>
  <div class="pb-12">
    <v-container fluid>
      <h2 class="title-text">Gold Filled Vs. Gold Plated</h2>
      <v-row>
        <v-col cols="12">
          <p>
            <img src="..\assets\guidereference\gold.jpg">
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "GoldTypesPage",
}
</script>
