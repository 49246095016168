import { createApp } from 'vue';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import 'vuetify/styles'
import App from './App.vue';

import './assets/custom-theme.css'; // For theme switching
import './scss/_typography.scss'; // For theme switching
import router from './router';

const vuetify = createVuetify({
    components,
    directives,
    display : {
      mobileBreakpoint : 'md'
    }, 
    icons: {
      defaultSet: 'mdi',
    }
  })

createApp(App).use(router).use(vuetify).mount('#app');
