<template>
  <div class="footer">
    <v-container fluid>
      <v-row>
        <v-col :cols="$vuetify.display.mobile ? 12 : 8">
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 4">
              <h3 class="widget-title">
                Products
              </h3>
              <ul class="fmenu">
                <li>
                  <a href="/products?cat=Findings">Findings</a>
                </li>
                <li>
                  <a href="/products?cat=Chains">Chains</a>
                </li>
                <li>
                  <a href="/products?cat=Mills">Mills</a>
                </li>
              </ul>
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 4">
              <h3 class="widget-title">
                Guide & Reference
              </h3>
              <ul class="fmenu">
                <li>
                  <a href="..\conversion">Conversion Tables</a>
                </li>
                <li>
                  <a href="..\gold">Gold Filled Vs. Gold Plated</a>
                </li>
                <li>
                  <a href="..\videos">LeachGarner Video</a>
                </li>
              </ul>
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 4">
              <h3 class="widget-title">
                <a href="/contact">Contact Us</a>
              </h3>
            </v-col>
          </v-row>
        </v-col>
        <v-col 
          :cols="$vuetify.display.mobile ? 12 : 4"
          class="flogo"
        >
          <a href="\about" class="text-md-right">
            <img 
              src="..\assets\footer\footerlogo.jpg"
              alt="Footer Logo"
              class="float-md-right footerimg-style"
            />
          </a>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="12">
            <p class="copyright">
              Copyright © 2024  Leach & Garner . All Rights Reserved.
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "FooterPage",
}
</script>

<style>
.footer-section {
  padding-top: 40px 0;
  background: url(../assets/footer/pro-bg.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

h3.widget-title {
  font-size: 16px !important;
  font-weight: 600;
  color: #333333;
  padding-bottom: 20px;
}

.footer {
  padding: 20px 0 0 0;
  border-top: 3px solid #eee;
  min-height: 250px;
}

ul.femu {
  margin-bottom: 20px;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer ul li a {
  color: #8c8c8c;
  font-size: 13px;
}

.widget-title a {
  color: #000;
  text-decoration: none;
}

.copyright {
  font-size: 12px !important;
  color: #000;
}

@media (min-width: 992px) {
  .footer-section {
    padding-bottom: 280px;
  }

  .footer {
    width:100%;
    background: rgba(255, 255, 255, 0.4);
    bottom: 0;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.footerimg-style {
  min-height: 45vh;
  min-width: 100%;
}

</style>
