<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table border="0" cellpadding="1" cellspacing="1" class="about-logo" style="width:100%;">
          <tbody>
            <tr>
              <td style="width: 40%;">
                <table border="0" cellpadding="2" cellspacing="5" style="width:100%;">
                  <tbody>
                    <tr>
                      <td style="width: 40%;">
                        <v-img 
                          alt=""
                          src="https://www.lghk.com/attachment/upload/files/inverness.png"
                          width="100%"
                        >
                        </v-img>
                      </td>
                      <td style="vertical-align: top;">
                        <div class="ml-10">
                          <h3>Peace of Mind Ear Piercing</h3>
                          <p>
                            At Inverness, we understand ear piercing. We get that for you,
                            it’s about so much more than an earring. It’s about trust. It’s a rite of passage. A
                            special moment between a mother and her daughter. Make those moments wonderful and
                            carefree. Use Inverness to ensure your moment will be a positive one.
                            <br>
                            <br>
                            <a href="http://invernesshk.com/">
                              Learn more about the Inverness Corporation and their products
                            </a>
                            
                            <a href="http://invernesshk.com/">
                              Go To Inverness website (www.invernesshk.com)
                            </a>
                          </p>
                        </div>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InvernessPage",
};
</script>
