<template>
  <v-app>
    <v-app-bar class="light-theme">
      <v-container  fluid class=" d-flex">
        <v-row>
          <v-col 
            cols="8"
            sm="4"
          >
            <a href="/about">
              <v-img
                :src="logo"
                alt="logo"
                class="my-2"
                contain
                max-height="80"
                max-width="366"
                transition="false"
              />
            </a>
          </v-col>
          <v-col 
            v-if="!$vuetify.display.mobile"
            cols="6"
            offset="2"
          >
            <ul class="d-flex justify-space-around navbar align-end">
              <li v-for="(link, i) in links" class="nav-item" :key="i">
                <a
                  v-if="!link.subLinks"
                  class="nav-link" 
                  :href="$router.resolve({path: link.to}).href"
                >
                  {{ link.text }}
                </a>
                <v-menu
                  v-if="link.subLinks"
                  attach
                  eager
                  :offset-y="$vuetify.display.mdAndUp"
                  :open-on-hover="true"
                  open-delay="250"
                  transition="slide-y-transition"
                >
                  <template #activator="{props}">
                    <a
                      class="nav-link" 
                      :href="$router.resolve({path: link.to}).href"
                      v-bind="props"
                    >
                      {{ link.text }}
                    </a>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in link.subLinks"
                      :key="index"
                      :value="index"
                    >
                      <a
                        class="nav-link" 
                        :href="$router.resolve({path: item.to , query: item.query}).href"
                      >
                        {{ item.text }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>
            </ul>
          </v-col>
          <v-col 
            v-if="$vuetify.display.mobile"
            class="justify-end align-center d-flex"
          >
            <v-app-bar-nav-icon 
              aria-label="Open Mobile Menu"
              @click.stop="drawer = !drawer"
              size="x-large"
            >
            </v-app-bar-nav-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.display.mobile"
      v-model="drawer" 
      clipped
      width=240 
      absolute
    >
      <v-list
        flat 
        dense 
        nav 
        class="py-1"
      >
        <div v-for="(link, i) in links" :key="i">
          <v-list-group v-if="link.subLinks">
            <template #activator="{props}">
              <v-list-item v-bind="props">{{ link.text }}</v-list-item>
            </template>
            <v-list-item
              v-for="(sub, ii) in link.subLinks"
              :key="ii"
              dense
              router 
              :title="sub.text"
              :to="sub.to"
            >
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            dense
            router
            :title="link.text"
            :to="link.to"
          >
          </v-list-item>
        </div> 
      </v-list>
    </v-navigation-drawer>
    <router-view 
      class="content"
    />
    <Footer/>
  </v-app>
</template>

<script>
import logo from "@/assets/logo.png"
import Footer from "@/views/FooterPage.vue"

export default {
  components: {
    Footer
  },
  data() {
    return {
      drawer: false,
      logo: logo,
      links: [
        {
          to     : '/about',
          text   : 'About Us',
        },
        {
          to     : '/products',
          text   : 'Products',
          subLinks : [
              {
                  text : 'Findings',
                  to    : '/products?cat=Findings',
                  query : {"cat": "Findings"}
              },
              {
                  text : 'Chains',
                  to    : '/products?cat=Chains',
                  query : {"cat": "Chains"}
              },
              {
                  text : 'Mills',
                  to    : '/products?cat=Mills',
                  query : {"cat": "Mills"}
              },
              {
                  text : 'Inverness',
                  to    : '/inverness',
              },
          ]
        },
        {
          to     : '/about',
          text   : 'Guide & Reference',
                    subLinks : [
              {
                  text : 'Conversion Tables',
                  to    : '/conversion',
              },
              {
                  text : 'Gold Filled Vs. Gold Plated',
                  to    : '/gold',
              },
              {
                  text : 'LeachGarner Video',
                  to    : '/videos',
              },
             
          ]
          
        },
        {
            to     : '/contact',
            text   : 'Contact Us',
        },
      ]
    };
  },
  mounted() {
    console.log(this.$router.resolve({path: "/products?cat=Mills", query:{"cat": "Mills"}}))
  }
};
</script>

<style>
.light-theme {
  background-color: #ffffff; 
  color: #be9b59;
}

.light-theme .navbar {
  background-color: #ffffff;
}

.light-theme .nav-link {
  color: #be9b59;
	font-size: 17px;
	display: inline-block;
	text-transform: capitalize;
	font-weight: 600;
}

.light-theme .nav-link:hover {
  color: #7a643b;
}
.v-toolbar {
  padding: 10px 0;
}
.v-toolbar__content {
  height: 80px !important;
}

.navbar {
  list-style-type: none;
  height: 80px;
}

.bi {
  margin-right: 5px;
}
.content {
  margin-top: 120px !important;
  min-height: calc(100vh - 200px);
}
.v-layout {
  background-color: #f9fafc;
}
.v-menu > .v-overlay__content {
	top: 100px !important;
}

body {
  font-family: 'OpenSans', sans-serif;
  transition: background-color 0.3s, color 0.3s;
}
.title-text {
  color: #be9b59;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 960px) {
  .v-toolbar__content {
    height: 50px !important;
  }
  
  .v-list-item__content {
    font-size: 13px;
  }

  .content {
    margin-top: 80px !important;
  }
}
.v-container--fluid {
	max-width: 1250px;
}
</style>
